<template>
  <div class='login_continer'>
    <vue-particles color="#409EFF" :particleOpacity="0.7" :particlesNumber="120" shapeType="circle" :particleSize="4" linesColor="#409EFF" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4"
      :linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push"> </vue-particles>
    <div class="login_box">
      <div class="admin_title">Crystal Ball Management System</div>
      <el-form ref="loginFromRef" label-width="0px" class="logn_from" :model="form" :rules="LoginFromRules">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input prefix-icon="el-icon-user" clearable v-model="form.username"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input prefix-icon="el-icon-lock" v-model="form.password" show-password></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="Login">登录</el-button>
          <el-button type="info" @click="restLoginFrom" plain>重置</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import { login } from "../api/index.js";
import { userRoleList } from "@/api//role.js";

// 测试数据权限
const authorityList = [
  {
    id: 98,
    permitName: "通过角色id查询权限",
    keyword: "permit:getByRoleId",
    description: "通过角色id查询权限",
    createDate: 1645514141000,
  },
  {
    id: 86,
    permitName: "通过id查询角色",
    keyword: "rePaymentPlan:writeoffPass",
    description: "通过id查询角色",
    createDate: 1645514141000,
  },
];

export default {
  name: "",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      LoginFromRules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    // 重置表单
    restLoginFrom() {
      this.$refs.loginFromRef.resetFields();
    },
    Login() {
      // 表单预验证
      this.$refs.loginFromRef.validate((valid) => {
        // console.log(valid);
        if (valid) {
          login(this.form)
            .then((res) => {
              console.log(res);
              this.$message.success("登录成功");
              const token = res.data.token;
              window.sessionStorage.setItem("token", token);
              const userInfo = JSON.stringify([
                {
                  userInfo: res.data.userInfo,
                },
              ]);
              window.sessionStorage.setItem("userInfo", userInfo);

              // 查询用户角色id的权限--存储
              const userId = res.data.userInfo.id;
              userRoleList(userId).then((Value) => {
                console.log(Value);
                window.sessionStorage.setItem("keyword", JSON.stringify(Value.data[0].keyword));

                this.$router.push("/home");
              });

              // 获取用户权限数据 -- 存储
              const { permits } = res.data.userInfo;
              this.$store.commit("setAuthority", permits);
              window.sessionStorage.setItem("authorityList", JSON.stringify(permits));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
#particles-js {
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
}
.login_continer {
  background-color: #2b4b6b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_box {
    width: 500px;
    height: 350px;
    background-color: #2b4b6b80;
    box-shadow: -1px -2px 5px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: -1px -2px 5px 0px rgba(255, 255, 255, 0.75);
    border-radius: 3px;
    text-align: center;
    position: relative;
    .admin_title {
      font-size: 26px;
      line-height: 130px;
      color: white;
      font-weight: 700;
      //   border: 1px solid black;
    }
    .logn_from {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      .btns {
        display: flex;
        justify-content: right;
      }
    }
  }
}
</style>
