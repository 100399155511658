<template>
  <div class='tagsbox'>
    <el-tag disable-transitions @click='tagsmenu(tag,index)' @close='removetags(tag,index)' v-for="(tag,index) in tagList" :key="tag.name" :closable="tag.url=='/property'?false:true"
      :type="tag.url==$route.path?'':'info'" :effect="tag.url==$route.path?'dark':'plain'">
      {{tag.name}}
    </el-tag>
  </div>
</template>
    
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "tagsview",
  data() {
    return {};
  },
  computed: {
    //引入vuex中的taglist数据
    ...mapState({
      tagList: (state) => state.tab.tagList,
    }),
  },
  created() {},
  methods: {
    ...mapMutations({
      closeTab: (state) => state.tab.closeTab,
    }),
    //选中tags标签
    tagsmenu(item, index) {
      this.$router.push({ path: item.url });
    },
    //移除tags标签
    removetags(item, index) {
      console.log(item, index);
      let length = this.tagList.length - 1;
      this.$store.commit("closeTab", item);

      var reg = RegExp(/carinfo/); //检索是否含有'carinfo'
      console.log(this.$route.path.match(reg));

      ///carinfo为资产管理的子菜单,所以移除时直接返回/property
      if (
        this.$route.path.match(reg) &&
        item.url.match(reg) &&
        this.$route.path == item.url
      ) {
        this.$router.push({ path: "/property" });
      } else {
        //避免在查看其它菜单且关闭/carinfo时,误切换路径
        if (
          (item.url.match(reg) && !this.$route.path.match(reg)) ||
          (item.url.match(reg) &&
            this.$route.path.match(reg) &&
            this.$route.path != item.url)
        )
          return;
        console.log("123123");
        //移除的是选中本身
        if (this.$route.path == item.url) {
        //表示删除的是最右边的一个
          if (length == index) {
            //最后一个标签 判断
            if (length == 0) {
              if (this.$route.path != "/property") {
                this.$router.push({ path: "/property" });
              }
            } else {
              this.$router.push({ path: this.tagList[index - 1].url });
            }
          } else {
            this.$router.push({ path: this.tagList[index].url });
          }
        }
      }
    },
  },
};
</script>
    
<style lang='less' scoped>
.el-tag {
  margin-right: 5px;
  cursor: pointer;
}
</style>